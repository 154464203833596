.experience-figures-small {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
.figure-container {
    margin: 10px;
}

.figure {
    min-width: 40%;
    max-width: 500px;
    min-height: 450px;
    max-height: 1000px;
    margin: 50px 30px;
}

.img-container {
    display: flex;
    justify-content: center;
    max-width: 100%;
    
    margin: 10px;
}

.experience-description {
    display: block;
    text-align: center;
    margin: 10px 0;
    font-family: 'Josefin Sans';
    line-height: 1.5;
}

.experience-photo {
    max-width: 100%;
    max-height: 100%;
}

.experience-photo:hover {
    background-color: var(--color4);
    opacity: .5;
}

.experience-titles {
    font-size: 25px;
    color: var(--color3);
}

.github {
    color: orange;
}

@media screen and (max-width: 700px){
    .experience-figures-small figure {
        min-width: 200px;
        max-width: 500px;
        max-height: 1000px;
        height: auto;
        margin: 30px auto;
    }
        
  
  }