.work-header {
    font-family: Orbitron;
    font-size: 50px;
    text-align: center;
    margin: 100px 0px;
    color: var(--color3)
}

.page-container {
    max-width: 100vw;
}
.work-experience-container{
    display: flex;
    flex-wrap: wrap;
    margin: 10em auto;
    align-items: start;
    align-content: start;
    max-width: 100%;
}

.work-history {
    width: 65%;
}

.skills {
    width: 30%;
    margin: 0 auto;
}

.skills-container {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.previous-work-container {
    padding: 60px;
    min-height: 500px ;
    box-shadow: var(--color5);
    border-radius: 10%;
    border: 3px solid var(--color3) ;
    margin-left: 30px;
    width: 80%;
    max-width: 100%;
    font-family: 'Josefin Sans'; 
}

.info-container {
    width: 100%;
}

.akqa-logo {
    width: 100px;
}

.akqa-logo img {
    width: 100%;
    height: 100%;
}

.work-heading {
    
    font-family: 'Josefin Sans';   
    color: black;
    font-size: 1.5em;

}


.work-number {
    font-family: 'Josefin Sans';   
    color: var(--color3);
    font-size: 2.5em;;
}
.AKQA-section h4 {
    display: flex;
}

.date {
    color: orange;
    font-family: 'Josefin Sans';  

}

.quote {
    display: block;
    width: 60%;
    text-align: center;
    color: var(--color3);
    margin: 15px auto;
}

.gleap-video-container {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 20px auto;
}


.table-container {
    margin: 0 auto;
    width: 90%;
    max-width: 90%;
}

.table {
    max-width: 90%; 
    margin: 0 auto;
}

.table, .table th, .table td {
    
    border: 2px orange solid;
    padding: 20px;
    
}

.work-type-table {
    width: 15%;
}
.highlights-tale {
    width: 42.5%;
}

.table ul {
    list-style-type: circle;
}

.table ul li {
    margin-bottom: 10px;
}

.important-word {
    color: orange;
    font-weight: bold;
}

.work-types {
    margin: 50px auto;
    margin-left: 2%;
}
.work-types ol li {
    margin: 25px 0;
}

a:hover {
    color: orange;
    cursor: pointer;
}

@media screen and (max-width: 1300px) {
    .work-history {
        width: 80%;
        margin: 20px auto;
    }
    
    .skills {
        width: 80%;
        margin: 100px auto;
    }
}

@media screen and (max-width: 800px) {
    .work-history {
        width: 100%;
        flex-shrink: 3;
        margin: 20px auto;
    }
    .work-header {
        font-size: 2em; 
        flex-shrink: 2;
    }
    
    .skills {
        width: 100%;
        margin: 100px auto;
    }

    .previous-work-container {
        width: 95%;
        padding: 0px 0px;
        margin: 0px auto;
        
        border: 0px;
    }
    .table-container {
        display: flex;
        justify-content: center;
        width: 100;
    }
    .table {
        width: 100%;
        margin: 0 30px;
    }
    .table, .table th, .table td {
    
        border: 2px orange solid;
        padding: 20px;
        
    }
    
    .gleap-video-container iframe {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .table, .table th, .table td {
    
        border: 1px orange solid;
        padding: 10px;
        
    }
}