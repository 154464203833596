.skill-container {
    font-family: 'Josefin Sans';
    width: 31%;
    margin-bottom: 40px;
    text-align: center;
}

.skill-container-icon {
    width: 75px;
    height: 75px;
    margin: auto;
}

.icon-img {
    max-width: 100%;
    max-height: 100%;
}

.icon-name {
    font-size: 1.2em;        
    color: var(--color3);
    text-align: center;
}

.skill-title {
    margin-top: 15px;
}

.skill-level {
    color: orange;
}