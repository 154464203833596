
.project-header {
    font-family: Orbitron;
    font-size: 50px;
    text-align: center;
    margin: 100px 0px;
    color: var(--color3)
}

.experience-container {   
    margin: 20px 10px;
    min-height: 700px;
    max-width: 100vw;
}

.figure {
    border: 2px solid var(--color3);
  
}

.mainProject-container {
    
    margin: 0 auto;
}

.experience-figures-small {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin: 0 auto;

}

@media screen and (max-width: 700px){
    .experience-figures-small, .mainProject-container {
        max-width: 100%;
    }
    .experience-figures-small figure {
        min-width: 0px;
        max-width: 500px;
        max-height: 1000px;
        margin: auto;
    }
        
  
  }