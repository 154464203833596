
:root {
    --backgroundColor1: black;
    --styleColor1: orange;
    --styleColor2: red;
    --color1: rgba(0, 16, 17, 1);
    --color2: rgba(9, 58, 62, 1);
    --color3: rgba(58, 175, 185, 1);
    --color4: rgba(100, 233, 238, 1);
    --color5: rgba(151, 200, 235, 1);
    }
    
.home-header {
    font-family: Orbitron;
    font-size: 50px;
    text-align: center;
    margin: 100px 0px;
    color: var(--color3)
}

h3 {
    text-align: center;
    position: relative;
    bottom: 50px;
    width: 100%;
    font-family: Lobster;
    font-size: 1.5em;
    color: var(--styleColor1);
}

.home-container {
    max-width: 100%;
}

.about-container {
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

/* This aspect of the about section should be on the left side contained in a bubble */
.about-section {
    width: 50%;
    margin: 0 30px;
}
.about-content {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 700px;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    box-shadow: var(--color5);
    background-color: var(--color4);
    border-radius: 10%;
    border: 3px solid var(--color3) ;
    line-height: 1.5;
    flex-grow: 1;
}

.about-content p, .about-content ul {
    margin: 2% 7%;
    font-family: 'Josefin Sans';
    color:whitesmoke;
    flex-shrink: 1;
}

.main-photo {
    max-width: 40%;
    display: inline-block;
    margin: 20px 0px 20px 5%;
    border-radius: 20%;
    overflow: hidden;
}

.main-photo img {
    max-width: 100%;
    max-height: 100%;
}

.inline {
    font-size: .9em;
    display: inline-block;
    max-width: 40%;
    margin-left: 15px;
    height: 60%;
}

.inline br {
    line-height: 300px;
}

.block {
    display: block;
    margin-top: 10px 15px;
}

.about-content{
    background-image: url('../../../pics/stars.png');
    opacity: 1.2;
    
}

/* This part of the about section should be on the right side and will be contained in a bubble */
.hobbies-section {
    width: 45%;
    margin: auto 0px;

}

.hobbies-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    display: block;
    justify-content: center;
    background-image: url('../../../pics/ryan-hutton-Jztmx9yqjBw-unsplash.jpg');
    background-position: center;
    flex-grow: 1;
    border-radius: 10%;
    border: 3px solid var(--color3) ;
    align-content: space-around;
    
    margin-bottom: 20px;
 }
 
 
 .hobbies-description h3{
    text-align: center;
 }
 
 
 
 .hobbies-container figure {
    max-width: 100%;
    display: grid;
    justify-content: center;
    margin: 20px auto;
    
 }
 
 .hobbies-container figure figcaption {
     margin: 10px 20px;
     color: whitesmoke;
     font-family: 'Josefin Sans';
     text-align: center;
 }
 
 .video-container {
    margin: 10px auto;
    border: solid --color3 5px;
    /* max-width: 500px;
    max-height: 400px; */
 }
 /* .video-container iframe {
    width: 100%;
    height: 100%;
 } */

 .first-hobby{
     
     grid-row-start: 1;
     grid-row-end: 2;
 }
 

 
 .second-hobby {
     grid-row-start: 2;
     grid-row-end: 3;
 }
 .second-hobby video {
     margin: 0 auto;
     width: 50%;
     height: auto;
 }

 .hobbyName {
    color: orange;
    font-weight: bold;
 }
 
 @media screen and (max-width: 700px){

    .main-photo {
        display: block;
        width: 100%;
        margin: 15px auto;
    }

    .inline {
        text-align: center;
        max-width: 80%;
        display: block;
        margin: 15px auto;
    }
    .about-section, .hobbies-section {
        width: 100%;
        margin: 0 auto;
    }
    .video-container {
        margin: 10px auto;
        border: solid --color3 5px;
        max-width: 80%;
        max-height: 180px;
     }
     .video-container iframe {
        width: 100%;
        height: 100%;
     }

}

@media screen and (max-width: 1300px) {
    .about-container {
        justify-content: center;
    }
    .about-section, .hobbies-section {
        width: 80%;
        margin: 0 auto;
    }

    .hobbies-section {
        margin-top: 100px;
    }
    
}

@media screen and (max-width: 550px){
    .video-container {
        margin: 10px auto;
        border: solid --color3 5px;
        max-width: 300px;
        max-height: 180px;
     }
     .video-container iframe {
        width: 100%;
        height: 100%;
     }
     .about-section, .hobbies-section {
        width: 90%;
    }
    .about-container {
        width: 100%;
        margin: 0 auto;
    }
      

}