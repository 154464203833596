.experience-figure-1 {
    width: 60%;
    margin: 10px auto;
    align-self: center;
    display: block;
    min-width: 700px;
    padding: 15px;
}


.figure {
    border: 2px solid var(--color3);
  
}

.github {
    color: orange;
}

@media screen and (max-width: 700px){
  .experience-figure-1 {
    width: 90%;
    }
  .experience-figure-1 {
    min-width: 0px;
        max-width: 500px;
        max-height: 1000px;
        margin: auto;
  }
      

}