html {
    width: 100%;
}


.header {
    display: flex;
    justify-content: space-between;
    padding: 1%;
    background-color: black;
    /* color: aliceblue; */
    height: 10vh;
}

.logo-container {
    height: 100%;
    flex-shrink: 1;
    margin-right: 50px;
}

.logo {
    max-width:100%;
    max-height:100%;
    
}

nav {
    width: 40%;
    display: grid;
    align-content: center;
}

nav ul {
    display: flex;
    justify-content: space-between;
    align-content: center;
    text-decoration: none;
    width: 100%;    
}

.navEndPoint {
    text-decoration:dashed;
    font-size: 1.2em;
    color: aliceblue;
    align-self: center;
    justify-self: center;
    margin: 0 5px;
}
.navEndPoint:hover {
    
    color: orange;
}

.navEndPoint NavLink:hover {
    color: green;
}

@media screen and (max-width: 1100px) {
    nav {
        
        flex-grow: 1;
    }
}

@media screen and (max-width: 550px) {
    .header {
        padding: 0;
        font-size: .6em;
    }
    
    
}

@media screen and (max-width: 800px) {
    
    .header {
        padding: 0;
        font-size: 1em;
    }
    nav {
        width: 100%;
        display: flex;
    }
    nav ul {
        width: 100%;
    }
    .logo-container {
        display: none;
    }
    
    .navEndPoint {
        font-size: 1em;
    }
}